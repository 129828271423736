import { useEffect, useState } from "react";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import heroimg from "../../../assets/img/landing/heroimg.jpeg";
import { useLocation } from "react-router-dom";
import takLogo from "../../../assets/img/icons/tak_green.svg";
import {
  selectHeroVisibility,
  setHeroVisibility,
} from "../../../redux/slices/appSlice";
import { useSelector } from "react-redux";
import store from "../../../redux/redux";
import { useTranslation } from "react-i18next";
export default function Hero() {
  const { t } = useTranslation();
  //const [isHidden, setIsHidden] = useState(false);
  const location = useLocation();
  const isHeroVisible = useSelector(selectHeroVisibility);

  useEffect(() => {
    const hero = document.getElementById("hero");
    if (hero)
      document.addEventListener("scroll", () => {
        if (hero.getBoundingClientRect().bottom < 0) {
          store.dispatch(setHeroVisibility(false));
        }
      });
  }, []);

  useEffect(() => {
    const visible = location.pathname === "/";

    if (!visible) store.dispatch(setHeroVisibility(false));
  }, [location.pathname]);

  if (!isHeroVisible) return <></>;

  return (
    <div
      style={{
        height: "100dvh",
        width: "100vw",
        background: `url(${heroimg})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      id="hero"
    >
      <div
        className="h100 w100"
        style={{
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0.65) 100%)",
        }}
      >
        <div className="container h100">
          <div className="content h100 py-5">
            <div className="h100 d-flex flex-column justify-content-around">
              <div className="h100 d-flex align-items-center justify-content-center">
                <h1 className="h1-large white-color regular text-center">
                  {t("wall.hero.title")}
                  <span className="primary-color">{t("wall.hero.title2")}</span>
                  {t("wall.hero.title3")}
                </h1>
              </div>
              <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                {/* <div className="d-flex gap-2">
                  <span
                    className="bodytext-lg regular white-color text-center"
                    style={{ lineHeight: "30px" }}
                  >
                    {t("wall.hero.changedestiny")}
                  </span>
                  <img
                    src={takLogo}
                    alt="tak"
                    height={"18px"}
                    style={{ marginTop: "5px" }}
                  ></img>
                </div> */}
                <Button
                  icon="arrow_circle_down"
                  variant="blank"
                  className="white-color"
                  style={{ transform: `scale(2)` }}
                  onClick={() => {
                    window.scrollBy({
                      top: window.innerHeight + 1,
                      behavior: "smooth",
                    });

                    setTimeout(() => {
                      window.scrollBy({
                        top: 0,
                      });
                    }, 1000);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function useAppDispatch() {
  throw new Error("Function not implemented.");
}
