import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { iNft, NFT_SELL_STATUS } from "../../../models/iNft";
import Stars from "../Stars/Stars";
import TakLogo from "../../images/TakyonShortLight.svg";
import Icon from "../Icon/Icon";
import LikeButton from "../Button/LikeButton";
import { useSelector } from "react-redux";
import { isFavorite, selectUser } from "../../../../redux/slices/userSlice";
import helpers from "../../../../_Web/helpers/helpers";
import {
  getAmountOfGuests,
  getCityFromLocation,
  getCityShortFromLocation,
  getCountryFromLocation,
  getRegionFromLocation,
} from "../../../Utils/location";
import getLink, { LINK_TYPE } from "../../../../config/Links";
import { openModal } from "../../../Utils/modal";
import Share from "../Share/Share";
import TakOffersReceived from "./TakOffersReceived";
import TakPrice from "./TakPrice";
import TakShortId from "./TakShortId";
import { ReactNode, useEffect, useState } from "react";
import { COLLECTION_TYPE } from "../../../models/iCollection";
import {
  datetimeRangeToString,
  datetimeToString,
  formatMinutes,
} from "../../../Utils/generic";
import { openNftDetailPage } from "../../../../_Web/pages/TakPage/TakPage";
import { getTranslationExperience } from "../../../Utils/langutils";
import { fireTagManagerEvent } from "../../../Services/tagmanager";

export function NftTypeTag({ type }: { type?: string }) {
  const { t } = useTranslation();

  return (
    <div className="tag tag-black bodytext regular">
      {type === COLLECTION_TYPE.hotel || !type ? (
        <>
          <Icon
            size={24}
            icon="bed"
            fill
            style={{ fill: "white", color: "white" }}
          ></Icon>
          <span className="bodytext-sm regular">{t("wall.classic.stay")}</span>
        </>
      ) : null}

      {type === COLLECTION_TYPE.experience ? (
        <>
          <Icon
            size={20}
            icon="nordic_walking"
            fill
            style={{ fill: "white", color: "white" }}
          ></Icon>
          <span className="bodytext-sm regular">
            {t("wall.classic.experience")}
          </span>
        </>
      ) : null}
    </div>
  );
}

interface Props {
  nft: iNft;
  small?: boolean;
  preventAspectRatio?: boolean;
  style?: React.CSSProperties;
  addon?: ReactNode;
  isFavorite?: boolean;
}

export default function TakCard({
  nft,
  preventAspectRatio = false,
  style,
  addon,
  small,
}: Props) {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  // user is equal to nft._owner
  const isOwner = user?.email === nft?.owner;
  const type: COLLECTION_TYPE | undefined = nft?.payload?.type;
  const [expName, setExpName] = useState<string>("");
  const likeButtonVisible = user === undefined || !isOwner;

  let bgImage =
    nft?.images?.[0] ??
    nft?._collectionImages?.[0] ??
    nft?._collectionCensus?.images?.[0];

  if (bgImage && bgImage.startsWith("http://res.cloudinary.com")) {
    const startIndex = bgImage.indexOf("upload/") + 7;
    const endIndex = bgImage.indexOf("/", startIndex);

    const newUrl =
      bgImage.substring(0, startIndex) +
      "f_auto/w_1226" +
      bgImage.substring(endIndex);

    bgImage = newUrl;
  }

  useEffect(() => {
    if (!nft || !nft?.payload?.category) return;
    if (type !== COLLECTION_TYPE.experience) return;

    const exp = getTranslationExperience(nft, i18n);
    if (!exp) return;

    setExpName(exp.name);
  }, [nft, type, i18n.language]);

  if (!nft)
    return (
      <div
        style={{ border: "1px dashed darkgrey", width: "inherit" }}
        className="panel nft aspect-ratio"
      ></div>
    );

  let oldPrice = helpers.getOldPrice(nft);
  let currentPrice = helpers.getCurrentPrice(nft);

  //console.log("nft", nft);

  return (
    <div
      style={style}
      onClick={() => {
        fireTagManagerEvent("open_card");
        openNftDetailPage(nft._id);
      }}
      className={`panel nft cursor-pointer ${
        preventAspectRatio ? "" : "aspect-ratio"
      }`}
    >
      <div
        className="panel-bg"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="panel-shadow"></div>
      <div className="panel-header">
        {small && <NftTypeTag type={nft?.payload?.type} />}

        {!small && <TakOffersReceived nft={nft} />}
        <div className="gap-2" style={{ display: "inline-flex" }}>
          <Button
            className="btn btn-light-simple"
            circle
            iconSize={20}
            size="sm"
            icon="ios_share"
            onClick={(e) => {
              e.stopPropagation();
              fireTagManagerEvent("open_share");
              openModal({
                content: (
                  <Share
                    id={String(nft?._id)}
                    link={getLink(LINK_TYPE.TAK, "API", {
                      __NFTID__: String(nft?._id),
                    })}
                  />
                ),
              });
            }}
          />
          {likeButtonVisible && (
            <LikeButton
              nftId={nft._id}
              likeCount={nft?.likesCount ? nft.likesCount : 0}
            />
          )}
        </div>
      </div>
      <div className="height-50"></div>
      <div className="panel-bottom">
        <div className="panel-body">
          <div className="w-100">
            <div className="mb-2">
              {!small && <NftTypeTag type={nft?.payload?.type} />}
            </div>
            <div className="bodytext">
              <div style={{ width: "98%" }}>
                <p className="m-0 medium text-nowrap text-ellipsis">
                  {type === COLLECTION_TYPE.hotel || !type ? (
                    <>
                      {getCityFromLocation(nft._collectionCensus?.location)} (
                      {getCityShortFromLocation(
                        nft._collectionCensus?.location
                      )}
                      , {getRegionFromLocation(nft._collectionCensus?.location)}
                      ,{" "}
                      {getCountryFromLocation(nft._collectionCensus?.location)})
                    </>
                  ) : null}

                  {type === COLLECTION_TYPE.experience ? (
                    <>{expName ?? nft?.payload?.category}</>
                  ) : null}
                </p>
              </div>
              <div className="m-0 d-flex align-items-center gap-1 w100">
                {type === COLLECTION_TYPE.hotel || !type ? (
                  <>
                    <p
                      className="text-nowrap text-ellipsis"
                      style={{ maxWidth: "200px" }}
                    >
                      {nft._collectionCensus?.name}
                    </p>

                    {nft._collectionCensus?.stars ? (
                      <>
                        <Stars stars={nft._collectionCensus?.stars} />
                      </>
                    ) : null}
                  </>
                ) : null}

                {type === COLLECTION_TYPE.experience ? (
                  <p
                    className="text-nowrap text-ellipsis"
                    style={{ maxWidth: "200px" }}
                  >
                    {t("wall.duration")}:{" "}
                    {formatMinutes(nft?.payload?.duration)}
                  </p>
                ) : null}
              </div>

              {type === COLLECTION_TYPE.hotel || !type ? (
                <>
                  <p className="m-0">
                    {datetimeRangeToString(
                      nft?.payload?.checkin!,
                      nft?.payload?.checkout!
                    )}
                  </p>
                  {!small && (
                    <p className="m-0">
                      {getAmountOfGuests(nft).adults +
                        getAmountOfGuests(nft).childs +
                        " " +
                        t("wall.guests")}
                    </p>
                  )}
                </>
              ) : null}

              {type === COLLECTION_TYPE.experience ? (
                <>
                  {datetimeToString(
                    nft?.payload?.date,
                    nft._collectionCensus?.location?.gmt,
                    false,
                    true
                  )}
                </>
              ) : null}
            </div>
          </div>
          {!small && (
            <div>
              <p className="h3 medium">
                {nft.currentPrice && (
                  <TakPrice
                    price={Number(currentPrice)}
                    oldPrice={Number(oldPrice)}
                  />
                )}
              </p>
            </div>
          )}
        </div>
        {addon && <div className="w100">{addon}</div>}
        <div className="panel-footer pt-2 bodytext small light">
          {nft?.shortId && <TakShortId shortId={nft?.shortId} />}
          {!small && (
            <div
              style={{ maxWidth: "170px" }}
              className="m-0 d-flex align-items-center gap-2"
            >
              {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? (
                <span className="bodytext-sm-xs regular text-nowrap text-ellipsis">
                  {t("wall.owner")} {nft?._ownerName ? nft?._ownerName : null}
                </span>
              ) : null}
              <Icon
                size={22}
                icon="check_circle"
                style={{ fill: "#4282FF", color: "#4282FF" }}
              ></Icon>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
