import getLink, { LINK_TYPE } from "../../../config/Links";

import takyonLight from "../../../assets/img/icons/TakyonLight.svg";
import facebook from "../../../assets/img/icons/facebook.svg";
import instagram from "../../../assets/img/icons/instagram.svg";
import linkedin from "../../../assets/img/icons/linkedin.svg";
import { useTranslation } from "react-i18next";

export interface footerItemsProps {
  path: string;
  text: string;
  icon?: string;
  target?: string;
}

export default function Footer() {
  const { t } = useTranslation();

  const services: footerItemsProps[] = [
    { path: getLink(LINK_TYPE.WALL), text: t("footer.home") },
    { path: getLink(LINK_TYPE.FORCLIENTS), text: t("footer.forclients") },
    { path: getLink(LINK_TYPE.FORPARTNERS), text: t("footer.partners") },
    {
      path: "https://drive.google.com/uc?export=download&id=1fbd7bKBmXrs1ev2jbkSOix_HK827asZD",
      text: t("footer.presskit"),
    },
  ];

  const follow: footerItemsProps[] = [
    {
      path: getLink(LINK_TYPE.INSTAGRAM),
      text: "Instagram",
      icon: instagram,
      target: "instagram",
    },
    {
      path: getLink(LINK_TYPE.LINKEDIN),
      text: "LinkedIn",
      icon: linkedin,
      target: "linkedin",
    },
    {
      path: getLink(LINK_TYPE.FACEBOOK),
      text: "Facebook",
      icon: facebook,
      target: "facebook",
    },
  ];

  const support: footerItemsProps[] = [
    { path: "mailto:support@takyon.io", text: t("footer.contact") },
    { path: getLink(LINK_TYPE.SUPPORT), text: "FAQ" },
    { path: "mailto:support@takyon.io", text: "support@takyon.io" },
  ];

  const documents: footerItemsProps[] = [
    { path: getLink(LINK_TYPE.INNOVATIVE_STARTUP), text: t("footer.startup") },
    // { path: "Whitepaper", text: "Whitepaper" },
    { path: getLink(LINK_TYPE.TOS), text: t("footer.tos") },
    { path: getLink(LINK_TYPE.PRIVACY), text: t("footer.privacy") },
    { path: getLink(LINK_TYPE.COOCKIES), text: t("footer.cookies") },
  ];

  return (
    <footer className="border-top">
      <section className="container black-background">
        <div className="content">
          <div className="height-100"></div>
          <div className="">
            <div className="d-flex flex-column flex-md-row justify-content-md-between">
              <div className="d-flex flex-column align-items-start white-color">
                <img src={takyonLight} alt="" className="width-180" />

                <div className="height-16"></div>

                <div className="d-flex flex-column">
                  <span className="bodytext-xl regular dark-grey-color">
                    Takyon S.r.l
                  </span>
                  <span className="bodytext-xl regular dark-grey-color">
                    Via Crema 15 - 20135,
                  </span>
                  <span className="bodytext-xl regular dark-grey-color">
                    Milano
                  </span>
                </div>
                <span className="bodytext-xl regular dark-grey-color">
                  P.IVA 12424180961
                </span>
              </div>

              <hr className="light-grey-color my-3 my-lg-4" />

              <div className="d-flex flex-column flex-md-row gap-md-5">
                <div className="d-flex flex-column align-items-start white-color">
                  <span className="bodytext-xl medium">Menu</span>

                  <div className="height-16"></div>

                  {services.map((service, index) => (
                    <div key={index}>
                      <a
                        href={service.path}
                        className="bodytext-xl light white-color text-decoration-none"
                      >
                        {service.text}
                      </a>
                      <div className="height-8 only-desktop"></div>
                    </div>
                  ))}
                </div>

                <hr className="light-grey-color my-3 my-lg-4" />

                <div className="d-flex flex-column align-items-start">
                  <span className="bodytext-xl medium white-color">
                    {t("footer.support")}
                  </span>

                  <div className="height-16"></div>

                  {support.map((support, index) => (
                    <div key={index}>
                      <a
                        href={support.path}
                        className="bodytext-xl light white-color text-decoration-none"
                      >
                        {support.text}
                      </a>
                      <div className="height-8 only-desktop"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <hr className="light-grey-color my-3 my-lg-4" />

            <div className="d-flex flex-column flex-md-row align-items-start justify-content-md-between gap-md-5">
              <div className="d-flex gap-4 me-5">
                {follow.map((follow, index) => (
                  <div key={index}>
                    <a href={follow.path} className="dark-grey-color">
                      <img src={follow.icon} alt="" />
                    </a>
                  </div>
                ))}
              </div>

              <div className="height-16"></div>

              <div className="d-flex flex-column flex-md-row justify-content-md-between w100">
                {documents.map((document, index) => (
                  <div key={index}>
                    <a
                      href={document.path}
                      className="bodytext-xl light dark-grey-color text-decoration-none"
                    >
                      {document.text}
                    </a>
                    <div className="height-8 only-desktop"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="height-100"></div>
        </div>
      </section>
    </footer>
  );
}
