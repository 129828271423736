import { Form } from "react-bootstrap";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Title from "../Title/Title";
import Input from "../../../../assets/TakyonDesignSystem/components/Input/Input";
import Switch from "../../../../assets/TakyonDesignSystem/components/Switch/Switch";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import PartnerController from "../../../../assets/Controllers/PartnerController";
import { handleInputChange } from "../../../../assets/Utils/input";
import { useTranslation } from "react-i18next";
import getLink, { LINK_TYPE } from "../../../../config/Links";
import { fireTagManagerEvent } from "../../../../assets/Services/tagmanager";

const UTM_CAMPAIGN_KEY = "c524v36b47n587iotmnbvc2";

export default function BecomePartner({ title }: { title?: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isformSent, setIsFormSent] = useState(false);

  const [campaign, setCampaign] = useState("");

  useEffect(() => {
    let campaign: any = new URL(String(window.location)).searchParams.get(
      "utm_campaign"
    );

    if (!campaign) campaign = localStorage.getItem(UTM_CAMPAIGN_KEY);

    if (campaign) {
      localStorage.setItem(UTM_CAMPAIGN_KEY, campaign);
      setCampaign(campaign);
    }
  }, []);

  const [partnerInformation, setPartnerInformation] = useState<{
    campaign: string;
    hotelName: string;
    website: string;
    first_name: string;
    second_name: string;
    email: string;
    phoneNumber: string;
    hs_content_membership_notes: string;
    privacyPolicy: boolean;
  }>({
    campaign: "",
    hotelName: "",
    website: "",
    first_name: "",
    second_name: "",
    email: "",
    phoneNumber: "",
    hs_content_membership_notes: "",
    privacyPolicy: false,
  });

  const SendFormClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await PartnerController.requestPartnership({
        ...partnerInformation,
        campaign,
      });
      setPartnerInformation({
        campaign: "",
        hotelName: "",
        website: "",
        first_name: "",
        second_name: "",
        email: "",
        phoneNumber: "",
        hs_content_membership_notes: "",
        privacyPolicy: false,
      });

      fireTagManagerEvent("partner_submit");

      setIsFormSent(true);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <main id="web-page" style={{ overflowY: "scroll" }}>
      <section className="web-section-container">
        <div className="web-section-content">
          <div className="height-90"></div>

          <Title
            title={title ?? t("forpartners.section_6.1")}
            // subtitle={t("forpartners.section_6.2")}
            // text={t("forpartners.section_6.3")}
          />
        </div>
        {/* <div className="height-40"></div> */}
      </section>

      {!isformSent ? (
        <section className="web-section-container">
          <div className="web-section-content align-items-center">
            <form
              onSubmit={SendFormClick}
              className="becomepartner-form"
              style={{ maxWidth: "780px", width: "100%" }}
              id="become-partner-form"
            >
              <input name="source" type="hidden" value={pathname} />

              {/* <div className="d-flex justify-content-center">
              {t("forpartners.section_6.4")}
            </div>
            <hr /> */}

              <div className="d-flex flex-column flex-md-row gap-3 w100">
                <Form.Group className="w100">
                  <Form.Label>{t("forpartners.section_6.5")}</Form.Label>
                  <Input
                    type="text"
                    placeholder={t("forpartners.section_6.6")}
                    name="hotelName"
                    value={partnerInformation.hotelName}
                    onChange={(e) =>
                      handleInputChange(e, setPartnerInformation)
                    }
                  />
                </Form.Group>

                <Form.Group className="w100">
                  <Form.Label>{t("forpartners.section_6.7")}</Form.Label>
                  <Input
                    type="text"
                    name="website"
                    placeholder={t("forpartners.section_6.8")}
                    value={partnerInformation.website}
                    onChange={(e) =>
                      handleInputChange(e, setPartnerInformation)
                    }
                  />
                </Form.Group>
              </div>

              <div className="height-8"></div>
              {/* 
            <div className="d-flex justify-content-center">
              {t("forpartners.section_6.9")}
            </div>
            <hr /> */}

              <div className="d-flex flex-column flex-md-row gap-3 w100">
                <Form.Group className="w100">
                  <Form.Label>{t("forpartners.section_6.20")}</Form.Label>
                  <Input
                    type="text"
                    name="first_name"
                    placeholder={t("forpartners.section_6.21")}
                    value={partnerInformation.first_name}
                    onChange={(e) =>
                      handleInputChange(e, setPartnerInformation)
                    }
                  />
                </Form.Group>
                <Form.Group className="w100">
                  <Form.Label>{t("forpartners.section_6.22")}</Form.Label>
                  <Input
                    type="text"
                    name="second_name"
                    placeholder={t("forpartners.section_6.23")}
                    value={partnerInformation.second_name}
                    onChange={(e) =>
                      handleInputChange(e, setPartnerInformation)
                    }
                  />
                </Form.Group>
              </div>
              <div className="d-flex flex-column flex-md-row gap-3 w100">
                <Form.Group className="w100">
                  <Form.Label>{t("forpartners.section_6.12")}</Form.Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="name@email.com"
                    value={partnerInformation.email}
                    onChange={(e) =>
                      handleInputChange(e, setPartnerInformation)
                    }
                  />
                </Form.Group>
                <Form.Group className="w100">
                  <Form.Label>{t("forpartners.section_6.13")}</Form.Label>
                  <Input
                    type="number"
                    name="phoneNumber"
                    placeholder="+00 0000000000"
                    value={partnerInformation.phoneNumber}
                    onChange={(e) =>
                      handleInputChange(e, setPartnerInformation)
                    }
                  />
                </Form.Group>
              </div>

              {/* <div className="d-flex justify-content-center">
              {t("forpartners.section_6.14")}
            </div>
            <hr /> */}

              <Form.Group>
                <Form.Label>{t("forpartners.section_6.15")}</Form.Label>
                <Input
                  type="textarea"
                  name="hs_content_membership_notes"
                  placeholder={t("forpartners.section_6.16")}
                  value={partnerInformation.hs_content_membership_notes}
                  onChange={(e) => handleInputChange(e, setPartnerInformation)}
                />
              </Form.Group>

              <div className="height-8"></div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2">
                  <Switch
                    name="privacyPolicy"
                    onChange={(e) =>
                      setPartnerInformation({
                        ...partnerInformation,
                        privacyPolicy: e.target.checked,
                      })
                    }
                  />
                  <span className="bodytext-sm regular">
                    {t("forpartners.section_6.17")}{" "}
                    <a
                      onClick={() => {
                        navigate(getLink(LINK_TYPE.PRIVACY));
                      }}
                    >
                      <u>{t("forpartners.section_6.19")}</u>
                    </a>
                  </span>
                </div>
                <Button
                  text={t("forpartners.section_6.18")}
                  type="submit"
                  loading={isLoading}
                  disabled={
                    !partnerInformation.hotelName ||
                    !partnerInformation.website ||
                    !partnerInformation.first_name ||
                    !partnerInformation.second_name ||
                    !partnerInformation.email ||
                    !partnerInformation.phoneNumber ||
                    !partnerInformation.privacyPolicy
                  }
                />
              </div>
            </form>
            <div className="height-45"></div>
            <div className="height-145 only-mobile"></div>
          </div>
        </section>
      ) : (
        <section className="web-section-container">
          <div className="web-section-content align-items-center">
            <div>{t("forpartners.section_6.24")}</div>
            <div className="height-90"></div>
          </div>
        </section>
      )}
    </main>
  );
}
